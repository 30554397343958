import { ExclamationCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';

import {
  VesselInfos,
  Form,
  Collapse,
  ModalBlockNavigation,
} from '../../../components';
import { useCollapsePanels } from '../../../hooks';
import {
  useCancelDockingMutation,
  useCreateDockingMutation,
  useUpdateDockingMutation,
} from '../../../services/dockingApi';
import { usePatchStopoverMutation } from '../../../services/stopoverApi';
import { Docking as DockingType, DockingPlace, Vessel } from '../../../types';
import {
  formatDockingToForm,
  formatDockingToSave,
} from '../../../utils/formatters';
import { createDateString, isNullOrUndefined } from '../../../utils/utils';
import { hasUnallowedCargo } from '../../../utils/validators';
import { FormItemsStopover } from '../../stopover/stopoverForm/formItemsStopover';
import { DockingFormHeader } from '../dockingFormHeader/DockingFormHeader';
import { FormItemsFinancialOfficers } from '../formItemsFinancialOfficers';
import { ActualTimeOfArrivalModal } from './ActualTimeOfArrivalModal';
import { FormItemsBollardFender } from './formItemsBollardFender';
import { FormItemsManoeuvre } from './formItemsManoeuvre';
import { FormItemsStaymentSummary } from './formItemsStaymentSummary';
import {
  validateAnchoring,
  validateCablesDates,
  validateDraught,
  validateDwt,
  validateManoeuvreDates,
  validateOperationDates,
} from './validators';

const { Panel } = Collapse;

type DockingStaymentFormProps = {
  name: string;
  selectedDocking: DockingType | undefined;
  setSelectedDocking: (value: any) => void;
  selectedStopover: any;
  setSelectedStopover?: (value: any) => void;
  allowedBerth: any[] | undefined;
  currentDockingKey: number;
  setCurrentDockingKey: (value: any) => void;
  isVisibleForm: boolean;
  setIsVisibleForm: (value: boolean) => void;
  // addDocking: (defaultValue?: any, insertIndex?: number | undefined) => void;
  onBack?: () => void;
  tugboatsData: Vessel[] | undefined;
  cargosData: any;
  operatorsData: any;
  manoeuvreBoardContext?: boolean;
  formIsChanged: boolean;
  setIsFormChanged: (value: boolean) => void;
  setBlockNavigate: (value: boolean) => void;
  blockNavigate: boolean;
};

const collapsePanels = [
  'docking_manoeuvre',
  'operation',
  'bollards_and_fenders',
  'undocking_manoeuvre',
  'geral_data',
  'vessel',
  'stopover',
  'generalData',
  'financialOfficers',
];

export function DockingStaymentForm(props: DockingStaymentFormProps) {
  const {
    name,
    selectedDocking,
    setSelectedDocking,
    selectedStopover,
    setSelectedStopover,
    allowedBerth,
    currentDockingKey,
    setCurrentDockingKey,
    isVisibleForm,
    setIsVisibleForm,
    onBack,
    tugboatsData,
    cargosData,
    operatorsData,
    manoeuvreBoardContext,
    formIsChanged,
    setIsFormChanged,
    setBlockNavigate,
    blockNavigate,
  } = props;

  const [form] = Form.useForm();

  const { onChangeSwitch, onOpenPanel, openCollapsePanels } =
    useCollapsePanels(collapsePanels);

  // allowed cargo to selected berth
  const [allowedCargo, setAllowedCargo] = useState([]);
  // allowed operators to selected berth
  const [allowedOperators, setAllowedOperators] = useState([]);

  const [isCreatingNewDocking, setIsCreatingNewDocking] = useState(false);

  const [updateDocking, { isLoading: isLoadingUpdateDocking }] =
    useUpdateDockingMutation();

  const [createDocking, { isLoading: isLoadingCreateDocking }] =
    useCreateDockingMutation();

  const [patchStopover] = usePatchStopoverMutation();

  const [isATAModalOpen, setIsATAModalOpen] = useState(false);
  const [ataDate, setAtaDate] = useState<null | Moment>(null);

  useEffect(() => {
    if (selectedDocking) {
      setIsCreatingNewDocking(false);
    } else {
      setIsCreatingNewDocking(true);
      form.setFieldsValue({});
    }
  }, [selectedDocking]);

  function validateDocking(docking: DockingType): boolean {
    if (selectedDocking?.purpose === 'DOCK_IN_BERTH') {
      const checkUnallowedCargo = hasUnallowedCargo(allowedBerth, docking);
      if (checkUnallowedCargo.length > 0) {
        message.error(
          `A mercadoria ${checkUnallowedCargo[0].name} não é aceita no novo berço. Por favor, revise as informações de mercadoria`
        );
        return false;
      }

      if (!validateDraught(docking, allowedBerth)) return false;
      if (!validateDwt(docking, allowedBerth)) return false;
    }
    if (
      selectedDocking?.docking_manoeuvre?.manoeuvre_release === 'PENDING' &&
      selectedDocking?.undocking_manoeuvre?.manoeuvre_release === 'PENDING'
    ) {
      message.error(`Para salvar esse formulário primeiro libere a atracação`);
      return false;
    }

    if (!validateManoeuvreDates(docking)) return false;
    if (!validateOperationDates(docking)) return false;
    if (!validateCablesDates(docking)) return false;
    if (!validateAnchoring(docking)) return false;

    return true;
  }

  function handleFailedForm() {
    const errorFields = form
      .getFieldsError()
      .filter((field) => field.errors.length > 0);

    if (errorFields.length > 0) {
      form.getFieldInstance(errorFields[0].name).focus();
    }
  }

  async function handleFormSubmit(values: any) {
    if (!validateDocking(values)) return;

    if (!selectedStopover.actual_time_of_arrival && !ataDate) {
      setIsATAModalOpen(true);
      return;
    }

    if (ataDate) {
      const partialStopover = {
        id: selectedStopover.id,
        actual_time_of_arrival: createDateString(ataDate),
      };

      const updatedStopover = await patchStopover(partialStopover);
      if ('data' in updatedStopover) {
        message.success('Hora real de chegada salva com sucesso!', 5);
      }
    }

    if (currentDockingKey !== null) {
      const docking = {
        ...selectedDocking,
        ...values,
        undocking_manoeuvre: {
          ...values.undocking_manoeuvre,
          manoeuvre_release:
            selectedDocking?.undocking_manoeuvre?.manoeuvre_release,
        },
        docking_manoeuvre: {
          ...values.docking_manoeuvre,
          manoeuvre_release:
            selectedDocking?.docking_manoeuvre?.manoeuvre_release,
        },
      };
      const update = formatDockingToSave(docking);
      const updatedDocking = await updateDocking(update);
      if ('data' in updatedDocking && setSelectedStopover) {
        const { data } = updatedDocking;
        setSelectedStopover({
          ...selectedStopover,
          dockings: selectedStopover.dockings.map((docking: DockingType) => {
            if (docking.id === update.id) {
              return formatDockingToForm(data);
            }
            return docking;
          }),
        });
        message.success('Alterações salvas com sucesso!', 5);
      }
    } else {
      values.purpose = 'DOCK_IN_BERTH';
      const create = formatDockingToSave(values);
      create.stopover_id = selectedStopover?.id;
      const createdDocking = await createDocking(create);
      if ('data' in createdDocking && setSelectedStopover) {
        const { data } = createdDocking;
        const oldDockings = selectedStopover?.dockings || [];
        setSelectedStopover({
          ...selectedStopover,
          dockings: [...oldDockings, data],
        });
        message.success('Atracação criada com sucesso!', 5);
      }
    }

    setSelectedDocking(undefined);
    setCurrentDockingKey(null);
    setIsVisibleForm(false);
    setBlockNavigate(false);
    setIsFormChanged(false);

    form.resetFields();
  }

  useEffect(() => {
    form.setFieldsValue(selectedDocking);
    if (selectedDocking) {
      if (selectedDocking.purpose === 'DOCK_IN_BERTH') {
        const berthCargo = allowedBerth?.find(
          (berth: DockingPlace) =>
            berth.tag === selectedDocking?.docking_place?.tag
        )?.cargo_type;
        setAllowedCargo(berthCargo);
        const berthOperators = allowedBerth?.find(
          (berth: DockingPlace) =>
            berth.tag === selectedDocking?.docking_place?.tag
        )?.operators;
        setAllowedOperators(berthOperators);
        return;
      }
      setAllowedCargo(cargosData);
      setAllowedOperators(operatorsData);
    }
  }, [selectedDocking]);

  useEffect(() => {
    if (isVisibleForm === false) {
      setSelectedDocking(undefined);
      setCurrentDockingKey(null);
      form.resetFields();
    }
  }, [isVisibleForm]);

  function onSelectBerth(option: any) {
    const berthSelected = allowedBerth?.find(
      (berth: any) => berth.tag === option
    );

    if (berthSelected?.place_type === 'BERTH') {
      const berthCargo = allowedBerth?.find(
        (berth: any) => berth.tag === option
      ).cargo_type;
      setAllowedCargo(berthCargo);

      const berthOperators = allowedBerth?.find(
        (berth: any) => berth.tag === option
      ).operators;
      setAllowedOperators(berthOperators);
    } else {
      setAllowedCargo(cargosData);
      setAllowedOperators(operatorsData);
    }
  }

  const [cancelDocking, { isLoading: isLoadingCancelDocking }] =
    useCancelDockingMutation();

  function onCancelDocking(evt: any) {
    evt.stopPropagation();
    confirm({
      title: 'Deseja realmente cancelar esta atracação?',
      icon: <ExclamationCircleOutlined />,
      content:
        'Depois que salvar a escala a atracação cancelada não poderá ser reativada',
      onOk() {
        cancelDocking({ id: selectedDocking?.id || 0 });
      },
      okText: 'Sim',
      cancelText: 'Não',
    });
  }

  function panelHeaderTitle(title: string) {
    const date = form.getFieldValue('updated_at');

    return (
      <>
        {title}
        {!isNullOrUndefined(date) && (
          <span
            className="header-description"
            style={{ float: 'right', fontWeight: 'normal', fontSize: '13px' }}
          >
            Atualizado em: {moment(date).format('DD/MM/YYYY HH:mm')}
          </span>
        )}
      </>
    );
  }

  function onClose() {
    if (formIsChanged) {
      setBlockNavigate(true);
    } else {
      setBlockNavigate(false);
      if (onBack) {
        onBack();
      }
      setIsVisibleForm(false);
    }
  }

  function afterCloseModal() {
    setSelectedDocking(undefined);
    setCurrentDockingKey(null);
    form.resetFields();
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      name={name}
      id={name}
      onFinish={handleFormSubmit}
      onFinishFailed={handleFailedForm}
      initialValues={selectedDocking}
      onValuesChange={() => setIsFormChanged(true)}
    >
      <ModalBlockNavigation
        name={name}
        key={name}
        setHasEdited={setIsFormChanged}
        setShowModal={setIsVisibleForm}
        showModal={blockNavigate}
        setBlockNavigate={setBlockNavigate}
        afterCloseModal={afterCloseModal}
      />
      <ActualTimeOfArrivalModal
        showModal={isATAModalOpen}
        setShowModal={setIsATAModalOpen}
        setAtaDate={setAtaDate}
      />
      <DockingFormHeader
        formName={name}
        isLoading={
          isLoadingUpdateDocking ||
          isLoadingCreateDocking ||
          isLoadingCancelDocking
        }
        onBack={onClose}
        dockingRelease={selectedStopover.docking_release}
        isCreatingNewDocking={isCreatingNewDocking}
        showExpandAll
        onChangeSwitch={onChangeSwitch}
        openCollapsePanels={openCollapsePanels}
        editFormTitle={`Atracação ${currentDockingKey + 1}`}
        code={selectedStopover?.code}
        dockingCode={form.getFieldValue('code')}
        dockingStatus={form.getFieldValue('status')}
        createFormTitle="Nova Atracação"
        saveButtonTitle="Salvar Atracação"
        showCancelButton
        onCancelDocking={onCancelDocking}
      />
      <Collapse expandIconPosition="end" activeKey={openCollapsePanels}>
        <FormItemsStaymentSummary
          openCollapsePanels={openCollapsePanels}
          onOpenPanel={onOpenPanel}
          panelHeaderTitle={panelHeaderTitle}
          allowedBerth={allowedBerth}
          onSelectBerth={onSelectBerth}
          selectedDocking={selectedDocking}
          selectedStopover={selectedStopover}
          manoeuvreBoardContext={manoeuvreBoardContext}
          allowedCargo={allowedCargo}
          allowedOperators={allowedOperators}
          form={form}
        />
        <FormItemsManoeuvre
          formDocking={form}
          openCollapsePanels={openCollapsePanels}
          onOpenPanel={onOpenPanel}
          selectedDocking={selectedDocking}
          manoeuvre="docking_manoeuvre"
          tugboatsData={tugboatsData}
          allowedBerth={allowedBerth}
        />
        <FormItemsBollardFender
          openCollapsePanels={openCollapsePanels}
          onOpenPanel={onOpenPanel}
          panelHeaderTitle={panelHeaderTitle}
        />
        <FormItemsManoeuvre
          formDocking={form}
          openCollapsePanels={openCollapsePanels}
          onOpenPanel={onOpenPanel}
          selectedDocking={selectedDocking}
          manoeuvre="undocking_manoeuvre"
          tugboatsData={tugboatsData}
          allowedBerth={allowedBerth}
        />
        {manoeuvreBoardContext && (
          <Collapse
            activeKey={openCollapsePanels}
            onChange={onOpenPanel}
            expandIconPosition="end"
          >
            <Panel header="Dados da embarcação" key="vessel">
              <VesselInfos selectedVessel={selectedStopover?.vessel} showName />
            </Panel>
          </Collapse>
        )}
        {manoeuvreBoardContext && (
          <Collapse
            activeKey={openCollapsePanels}
            onChange={onOpenPanel}
            expandIconPosition="end"
          >
            <Panel forceRender header="Dados da escala" key="stopover">
              <FormItemsStopover
                readOnly
                form={form}
                selectedStopover={selectedStopover}
                stopoverRoot
              />
            </Panel>
          </Collapse>
        )}
        <FormItemsFinancialOfficers
          form={form}
          selectedStopover={selectedStopover}
          docking={selectedDocking}
          openCollapsePanels={openCollapsePanels}
          onOpenPanel={onOpenPanel}
        />
      </Collapse>
    </Form>
  );
}
