import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { Dispatch, SetStateAction } from 'react';

import { InnerCollapse, Tag } from '../../../../components';
import { useGetOperatorsGroupbyManifestAndOperatorCompanyQuery } from '../../../../services/ncmItemApi';
import { isNullOrUndefined } from '../../../../utils/utils';
import { AssignLoadingTable } from './assignLoadingTable/AssignLoadingTable';
import { ContainerOperatorCompany, ContainerManifest } from './styles';

const { Panel } = InnerCollapse;

type LoadingDataCollapseProps = {
  stopoverId: number;
  setNcmIdsToEdit?: Dispatch<SetStateAction<number[]>>;
  cargosNotAssigned?: boolean;
};

export function LoadingDataCollapse(props: LoadingDataCollapseProps) {
  const { stopoverId, setNcmIdsToEdit, cargosNotAssigned } = props;

  const { data: operators, isFetching: isFetchingOperatorsData } =
    useGetOperatorsGroupbyManifestAndOperatorCompanyQuery(
      {
        stopover_id: stopoverId,
        ncm_item_assigned: true,
        cargo_assigned: cargosNotAssigned ? false : '',
      },
      { skip: isNullOrUndefined(stopoverId) }
    );

  function handleCheckNcmItem(isChecked: boolean, ncmItem: any) {
    if (setNcmIdsToEdit && !isNullOrUndefined(ncmItem.ncm_item_id)) {
      if (isChecked) {
        setNcmIdsToEdit((prev) => [...prev, ncmItem.ncm_item_id]);
      } else {
        setNcmIdsToEdit((prev) =>
          prev.filter((item) => item !== ncmItem.ncm_item_id)
        );
      }
    }
  }

  if (isFetchingOperatorsData) {
    return <Skeleton active />;
  }

  if (operators !== undefined && operators.length > 0) {
    return (
      <InnerCollapse expandIconPosition="end" destroyInactivePanel={false}>
        {operators.map((operator, index) => (
          <Panel key={index} forceRender header={operator.name}>
            {operator.manifests?.map((manifest, index) => (
              <ContainerManifest key={index}>
                <div className="manifest-header">
                  <span className="manifest-text">Manifesto</span>
                  <span className="manifest-code">{manifest.code}</span>
                  <Tag
                    icon={
                      manifest.operation_direction === 'UNLOADING' ? (
                        <ArrowDownOutlined />
                      ) : (
                        <ArrowUpOutlined />
                      )
                    }
                  >
                    {manifest.operation_direction === 'UNLOADING'
                      ? 'Descarga'
                      : 'Carga'}
                  </Tag>
                </div>
                {manifest.operator_companies?.map((operatorCompany, index) => (
                  <InnerCollapse
                    expandIconPosition="end"
                    className="white-header"
                    key={index}
                    defaultActiveKey={index}
                    destroyInactivePanel={false}
                  >
                    <Panel
                      key={index}
                      forceRender
                      header={
                        <ContainerOperatorCompany>
                          <span className="consignee-name">
                            {operatorCompany.name}
                          </span>
                          <span className="consignee-cnpj">
                            {operatorCompany.cnpj}
                          </span>
                          <span className="consignee-items">
                            {operatorCompany.total_items || 0}
                          </span>
                        </ContainerOperatorCompany>
                      }
                    >
                      <AssignLoadingTable
                        rowKey="ncm_item_id"
                        onSelectItem={handleCheckNcmItem}
                        manifestCode={manifest.code}
                        stopoverId={stopoverId}
                        operatorId={operator.id}
                        operatorCompanyId={operatorCompany.id}
                        totalWeight={operatorCompany.total_weight}
                        totalItems={operatorCompany.total_items}
                        hasCheckboxColumn={setNcmIdsToEdit !== undefined}
                        isVehicleManifest={manifest.is_vehicle_manifest}
                        setNcmIdsToEdit={setNcmIdsToEdit}
                      />
                    </Panel>
                  </InnerCollapse>
                ))}
              </ContainerManifest>
            ))}
          </Panel>
        ))}
      </InnerCollapse>
    );
  }
  return <div />;
}
